<template>
  <main class="plans">
    <b-card>
      <b-card-title
        :style="{ 'background-color': plan.color, fontSize: '1.4rem' }"
      >
        {{ plan.name }}
      </b-card-title>
      <b-card-sub-title v-if="plan.last_plan">
        <router-link to="/contact-us">
          {{ $t("button.contact_us") }}
        </router-link>
      </b-card-sub-title>
      <b-card-text>
        <read-more
          class="sub-text text-sm-center"
          :more-str="$t('label.more')"
          :less-str="$t('label.less')"
          :text="plan.description ? plan.description : ''"
          :max-chars="50"
        ></read-more>
      </b-card-text>
      <b-card-body v-if="showFeatures">
        <b-list-group v-for="(feature, index) in plan.features" :key="index">
          <b-list-group-item :class="{ 'ood-background': isOdd(index) }">
            <h5>{{ feature.name }}</h5>
            <template v-if="feature.value_type === 2">
              <b-img
                v-if="feature.plan_feature_value === '1'"
                style="max-height:20px"
                :src="require('@/assets/imgs/checked.svg')"
              ></b-img>
              <b-img
                v-if="feature.plan_feature_value === '0'"
                style="max-height:20px"
                :src="require('@/assets/imgs/close.svg')"
              ></b-img>
            </template>

            <span v-else>{{ feature.plan_feature_value }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
      <b-card-footer v-if="!plan.last_plan" class="text-center">
        <p class="text-center font-bold">
          {{ plan.duration }} {{ $t("label.duration_date") }}
        </p>
        <strong class="font-bold"
          >{{ plan.price }} {{ $t("label.sar") }}</strong
        >
      </b-card-footer>
    </b-card>
  </main>
</template>
<script>
export default {
  name: "Plan",
  props: {
    showFeatures: {
      type: Boolean,
      default: false
    },
    plan: {
      type: Object,
      default: () => {}
    },
    plans: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isOdd(index) {
      if (index % 2 === 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
